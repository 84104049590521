import React from "react";
import EditBusiness from "../../component/businessDetailsScreen/EditBusiness";


const BusinessDetailEdit = () => {
  return (
    <div className="">
      <EditBusiness />
    </div>
  );
};

export default BusinessDetailEdit;

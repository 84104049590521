import React, { useEffect } from 'react';
import Image from '../../component/image';
import Text from '../../common/Text';
import LineChart from '../../common/Chart/LineChart';
import { getData } from '../../utils/api';
import { useState } from 'react';



const todayCont = [
  {
    title: 'Free Coffee with Breakfast Entree',
    time: '6:00 am – 11:00 am',
    highlight: '121 Redeemed',
    img: 'https://upload.wikimedia.org/wikipedia/commons/4/45/A_small_cup_of_coffee.JPG'
  },
  {
    title: 'Free Coffee with Breakfast Entree',
    time: '6:00 am – 11:00 am',
    highlight: '121 Redeemed',
    img: 'https://upload.wikimedia.org/wikipedia/commons/4/45/A_small_cup_of_coffee.JPG'
  },
  {
    title: 'Free Coffee with Breakfast Entree',
    time: '6:00 am – 11:00 am',
    highlight: '121 Redeemed',
    img: 'https://upload.wikimedia.org/wikipedia/commons/4/45/A_small_cup_of_coffee.JPG'
  },
  {
    title: 'Free Coffee with Breakfast Entree',
    time: '6:00 am – 11:00 am',
    highlight: '121 Redeemed',
    img: 'https://upload.wikimedia.org/wikipedia/commons/4/45/A_small_cup_of_coffee.JPG'
  }
]


const formatTimeRange = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);

  const options = { hour: "numeric", minute: "numeric", hour12: true };

  const formattedStartTime = start.toLocaleTimeString(undefined, options);
  const formattedEndTime = end.toLocaleTimeString(undefined, options);

  return `${formattedStartTime} – ${formattedEndTime}`;
};

const DashboardContent = () => {

  const [contentData, setcontentData] = useState();
  const [contDataCount, setcontDataCount] = useState()
  const [activeCount, setactiveCount] = useState(0)
  const [indraftCount, setindraftCount] = useState(0)
  const [expiredCount, setexpiredCount] = useState(0)
  const [offerCount, setofferCount] = useState(0)
  const [eventCount, seteventCount] = useState(0)
  const [privateCount, setprivateCount] = useState(0)


  const staticTop = [
    {
      title: "Active",
      countdata: activeCount,
      time: '',
      percentage: '',
      color:'text-green-400'
    },
    {
      title: "In Draft",
      countdata: indraftCount,
      time: '',
      percentage: '',
      color:'text-yellow-400'
    },
    {
      title: "Expired",
      countdata: expiredCount,
      time: '',
      percentage: '',
      color:'text-red-800'
    },
    {
      title: "Total",
      countdata: contDataCount,
      time: '',
      percentage: '',
      color:'text-black'
    },
  ]

  const middata = [
    {
      title: "Offers",
      countdata: offerCount,
      time: '',
      percentage: ''
    },
    {
      title: "Events",
      countdata: eventCount,
      time: '',
      percentage: ''
    },
    {
      title: "Private",
      countdata: privateCount,
      time: '',
      percentage: ''
    },
    {
      title: "Total",
      countdata: activeCount,
      time: '',
      percentage: ''
    },
  ]

  useEffect(() => {
    fetchAllEvents();
  }, [])


  const fetchAllEvents = async () => {
    try {
      const now = new Date();
      const res = await getData("event/created");
      if (res.data) {
        console.log(res.data,'events')
        setcontDataCount(res.data.count);
        const resData = res.data.events;
      
        const slicedata = resData.slice(0, 4);
        setcontentData(slicedata);

        const expired = resData.filter(event => {
          if (event.status === "published") {
            if (event.schedule.length === 1) {
              const eventDate = new Date(event.schedule[0].date);
              eventDate.setHours(0, 0, 0, 0); // Set time to midnight for date-only comparison
              return eventDate <= now; // Include events with the only upcoming date that has passed
            } else {
              // Check if any date in the schedule has passed
              const isAnyDateExpired = event.schedule.every(scheduleDate => {
                const eventDate = new Date(scheduleDate.date);
                eventDate.setHours(0, 0, 0, 0); // Set time to midnight for date-only comparison
                return eventDate < now;
              });
              // console.log(isAnyDateExpired,'isAnyDateExpired')
              
              return isAnyDateExpired;
            }
          }
          else{
            return false;
          }
        }).length;

        const expiredEvent = (eventType) => (resData.filter(event => {
          if (event.status === "published" && event.type === eventType) {
            if (event.schedule.length === 1) {
              const eventDate = new Date(event.schedule[0].date);
              eventDate.setHours(0, 0, 0, 0); // Set time to midnight for date-only comparison
              return eventDate <= now; // Include events with the only upcoming date that has passed
            } else {
              // Check if any date in the schedule has passed
              const isAnyDateExpired = event.schedule.every(scheduleDate => {
                const eventDate = new Date(scheduleDate.date);
                eventDate.setHours(0, 0, 0, 0); // Set time to midnight for date-only comparison
                return eventDate < now;
              });
              // console.log(isAnyDateExpired,'isAnyDateExpired')
              
              return isAnyDateExpired;
            }
          }
          else{
            return false;
          }
        }).length);
        setexpiredCount(expired)

        
        setactiveCount(res.data.events.filter(event => event.status === 'published').length - expired);
        setindraftCount(res.data.events.filter(event => event.status === 'drafted').length);
        console.log("FIRST", res.data.events.filter(event => event.type === 'private' && event.status === 'published' && event.schedule.some(scheduleDate => new Date(scheduleDate.date) < now)))
        seteventCount(res.data.events.filter(event => event.type === 'business_event' && event.status === 'published').length - expiredEvent('business_event'));
        setofferCount(res.data.events.filter(event => event.type === 'offer' && event.status === 'published').length - expiredEvent('offer'));
        setprivateCount(res.data.events.filter(event => event.type === 'private' && event.status === 'published').length - expiredEvent('private'));
      } else {
        console.log(res, "Error while fetching business profiles");
      }
    } catch (error) {
      console.error("Error while fetching events:", error);
    }
  };
  console.log(contentData)
  return (
    <div className="md:mx-10 mx-5 my-5">


      <div className="page-header mb-5">
        <h1 className='text-3xl font-bold {}'>Dashboard</h1>
        {/* <div className='text-base font-bold text-black'>
          Location: <span className='text-[#33B0B8]'>2115 Walnut St, Boulder, CO</span>
        </div> */}
      </div>
      {/* Statistics Summary */}
      <div className='text-base font-bold text-black mb-5'>
          Content
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-5">
        {/* Static content for demonstration */}
        {
          staticTop.map((item, index) => (
            <div key={index} className="bg-white rounded-xl border border-[#E7E7DB] shadow p-5">
              <h2 className="text-sm font-semibold text-[#6C7278] mb-2">{item.title}</h2>
              <p className={`text-2xl font-semibold ${item.color} mb-4`}>{item.countdata}</p>
              <p className="text-sm font-medium text-[#6C7278]"><span className='text-[#E15C7A]'>{item.percentage}</span> {item.time}</p>
            </div>
          ))
        }
      </div>

      <div className='text-base font-bold text-black mb-5'>
          Active
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-5 mb-5">
        {/* Static content for demonstration */}
        {
          middata.map((item, index) => (
            <div key={index} className="bg-white rounded-xl border border-[#E7E7DB] shadow p-5">
              <h2 className="text-sm font-semibold text-[#6C7278] mb-2">{item.title}</h2>
              <p className="text-2xl font-semibold text-[#1A1C1E] mb-4">{item.countdata}</p>
              <p className="text-sm font-medium text-[#6C7278]"><span className='text-[#E15C7A]'>{item.percentage}</span> {item.time}</p>
            </div>
          ))
        }
      </div>

      {/* Placeholder for Content Performance Line Chart */}
      {/* <div className="bg-white border border-[#E3E3D8] rounded-lg p-5 mb-5">
        <h3 className="text-base font-semibold mb-4">Content Performance </h3>

        <div className='w-full '>
          <LineChart />
        </div>

      </div> */}

      {/* Today's Content */}
      {/* <div className="bg-white border border-[#E3E3D8] rounded-lg p-5">
        <h3 className="text-base font-semibold mb-4">Weekly Content</h3>

        <div className="md:grid lg:grid-cols-4 md:grid-cols-3 gap-4 flex flex-nowrap contant-res-design">
          {contentData?.map((items, index) => {
            return (
              <div key={index} className="relative flex flex-col items-center justify-center overflow-hidden border border-grey rounded-xl contant-res-design-child">

                <div className="absolute flex justify-end w-full px-3 top-2 z-10">
                  <div className="text-xs rounded-lg font-medium bg-[#E8E51A] py-1.5 px-3">
                    {items.highlight}
                  </div>
                </div>
                <div className="flex h-full flex-col w-full bg-cover relative ">
                  <Image
                    src={
                      items.images[0].url ?? "https://via.placeholder.com/150"
                    }
                    className={"h-[165px] md:h-[165px] lg:h-[165px] xl:h-[165px]  w-full"}
                    alt={"evnt_img"}
                  />
                  <Text className={`text-sm font-normal bg-[#03B4BF] text-white px-2 py-1`}>
                  
                    { items.schedule && items.schedule.length > 0 && items.schedule[0].durations && items.schedule[0].durations.length > 0
                      ?formatTimeRange(items.schedule[0]?.durations[0].startTime,items.schedule[0]?.durations[0].endTime): 'No Time Mentioned'
                    }
                  </Text>
                  
                </div>
                <div className="w-full p-4 bg-white">
                  <Text className="font-medium text-lg text-black leading-5">
                    {items.title}
                  </Text>
                </div>
              </div>
            );
          })}
        </div>

      </div> */}
    </div>
  );
};

export default DashboardContent;
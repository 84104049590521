import {
    ErrorMessage,
    FieldArray,
    Form,
    FormikProvider,
    useFormik,
  } from "formik";
  import React, { useEffect, useState } from "react";
  import PrimaryButton from "../../common/FormElements/Button/PrimaryButton";
  import { Add } from "@mui/icons-material";
  import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
  import SecondaryButton from "../../common/FormElements/Button/SecondaryButton";
  import { useNavigate, useParams } from "react-router-dom";
  import { deleteData, getDataTemp, postData } from "../../utils/api";
  import { enqueueSnackbar } from "notistack";
  import { formatErrorMessage } from "../../utils/formatErrorMessage";
  import * as Yup from "yup";
  import { useAuthentication } from "../../context/authContext";
  import { ReactComponent as Deleteicon } from '../../assets/icons/delete.svg'
  import Text from "../../common/Text";
  import Swal from "sweetalert2";
  import imageCompression from 'browser-image-compression';
  import FacebookLogin from '@greatsumini/react-facebook-login';
  import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
  
  
  const PHONE_REGX =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  
  const Socialmedia = () => {
    const [loading, setLoading] = useState(false);
    const [isFacebookConnected,setfbconnected] = useState(true);
    const {
      businessUser: { businessProfile = '' } = {},
      fetchUserDetails,
    } = useAuthentication();
  
    // console.log(formik.values, ">>>>> value", id);
    const getPageAccessToken = (userAccessToken) => {
      fetch(`https://graph.facebook.com/me/accounts?access_token=${userAccessToken}`)
        .then(response => response.json())
        .then(data => {
          if (data.data && data.data.length > 0) {
            const pageAccessToken = data.data[0].access_token;
            connecttofb(pageAccessToken);
          } else {
            enqueueSnackbar(
              "No pages found or insufficient permissions.",
              {
                variant: "error",
              }
            );
          }
        })
        .catch(error => {
          enqueueSnackbar(
            "Error fetching page access token:"+error,
            {
              variant: "error",
            }
          );
        });
    };
    const connecttofb = async(token) =>{
      setLoading(true)
      const postdatafb = {
        accessToken: token,
      };
      const res = await postData('business-profile/connect/facebook',{
        ...postdatafb
      });
      if (res.data) {
        enqueueSnackbar(res.data.message ?? "", {
          variant: "success",
        });
        setfbconnected(true);
      } else {
        enqueueSnackbar(
          res.error?.message
            ? formatErrorMessage(res.error?.message)
            : "Something went wrong",
          {
            variant: "error",
          }
        );
      }
      setLoading(false)
    }
  
    const disconnectfb = async() =>{
      setLoading(true)
      const res = await postData('business-profile/disconnect/facebook');
      if (res.data) {
        enqueueSnackbar(res.data.message ?? "", {
          variant: "success",
        });
        setfbconnected(false);
      } else {
        enqueueSnackbar(
          res.error?.message
            ? formatErrorMessage(res.error?.message)
            : "Something went wrong",
          {
            variant: "error",
          }
        );
      }
      setLoading(false)
    }
  
    const getbusinessinfo = async() => {
      setLoading(true)
      const res = await getDataTemp('business-profile/'+businessProfile._id);
      if (res.data) {
        setfbconnected(res.data.businessProfile.isFacebookConnected);
      } else {
        enqueueSnackbar(
          res.error?.message
            ? formatErrorMessage(res.error?.message)
            : "Something went wrong",
          {
            variant: "error",
          }
        );
      }
      setLoading(false)
    }
    useEffect(() => {
      getbusinessinfo();
    }, []);
    return (
      <div className="md:mx-10 mx-5">
            <div className="flex items-center justify-between flex-wrap mb-5 gap-3">
              <Text className="text-[24px] mob:text-[16px] font-bold">
                Social Media
              </Text>
            </div>
            <div className="flex gap-6 flex-col">
                <div className="md:w-1/2 w-full">    
                    <div className="mt-6">
                    <div className="flex justify-between">
                        <h1 className="text-base font-semibold">
                        Social Media Connect
                        </h1>
                    </div>
                    </div>
                    <div className="social-media-input p-5 rounded-md bg-[#FCFCFC]">
                    <div className="mb-5 relative ">
                        {
                        !loading ?
                        isFacebookConnected ?
                            <button onClick={disconnectfb} className="font-semibold flex items-center justify-center gap-2 text-white bg-[#1a77f2] h-10 text-base px-2 rounded-sm">
                                <FacebookOutlinedIcon />
                                <span>Disconnect With Facebook</span>
                            </button>
                        :
                        <FacebookLogin
                            scope="public_profile,email,pages_show_list,pages_read_engagement,pages_manage_posts"
                            appId="418497171015003"
                            onSuccess={(response) => {
                            console.log('Login Success!', response);
                            getPageAccessToken(response.accessToken)
                            }}
                            onFail={(error) => {
                            console.log('Login Failed!', error);
                            }}
                            onProfileSuccess={(response) => {
                            console.log('Get Profile Success!', response);
                            }}
    
                            render={({ onClick }) => (
                            <button onClick={onClick} className="font-semibold flex items-center justify-center gap-2 text-white bg-[#1a77f2] h-10 text-base px-2 rounded-sm">
                                <FacebookOutlinedIcon />
                                <span>Connect With Facebook</span>
                            </button>
                            )}
    
                        />
                        :'Loading'
                        }
                    </div>
                    </div>
                </div>
            </div>
      </div>
    );
  };
  
  export default Socialmedia;
  
  